import React, { useEffect } from "react";
import { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { ReactComponent as Logo1 } from '../assets/svg/Logo Original.svg';
import Sidebar from "./SideBar";


export default function Navbar(props,{children}) {
  const location = useLocation()?.pathname
  const navigate = useNavigate()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav
      className="relative flex flex-wrap items-center shadow-md bg-white justify-between px-2 py-3"
    >

      <div className="container py-3 px-2 md:px-4  md:mx-auto flex flex-row flex-wrap items-center justify-between">
        
      <div className="flex sm:hidden justify-between w-auto static">
          <div className="sm:text-2xl flex items-center gap-2">
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
            <div className="text-2xl fa fa-bars fill-slate-700 cursor-pointer" onClick={()=>toggleSidebar()} ></div>
            
          </div>
        </div>

        <div className="flex justify-between w-auto static">
          <div className="sm:text-2xl flex items-center gap-2 text-md font-bold">
            <div className="w-28 -my-3 ">
              <Logo1/>    
            </div>
          </div>
        </div>
        <div className="sm:flex gap-5 text-xl xl:gap-20 hidden font-bold">
              <div
                            className={`border-b-4 cursor-pointer transition ease-in-out delay-100 w-min ${
                                location === "/home"
                                    ? "border-emerald-500"
                                    : "hover:border-emerald-500 border-transparent"
                            }`}
                            onClick={() => navigate("/home")}
                        >
                            Inicio
                        </div>
                        <div
                            className={`border-b-4 cursor-pointer transition ease-in-out delay-100 w-min ${
                                location === "/service"
                                    ? "border-rose-500"
                                    : "hover:border-rose-500 border-transparent"
                            }`}
                            onClick={() => navigate("/service")}
                        >
                            Servicios
                        </div>
                        <div
                            className={`border-b-4 cursor-pointer transition ease-in-out delay-100 w-min ${
                                location === "/about"
                                    ? "border-yellow-300"
                                    : "hover:border-yellow-300 border-transparent"
                            }`}
                            onClick={() => navigate("/about")}
                        >
                            Nosotros
                        </div>
        </div>
        <div
          className="sm:block hidden items-center rounded"
          id="example-navbar-warning"
        >
         <button className="text-xl bg-green-600 hover:bg-green-800 text-white font-bold p-3 rounded-lg shadow-lg">
            Contáctanos
                        </button>
        </div>
      </div>
    </nav>
  );
}
