import React from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import IconComponent from "components/IconComponent";
import SideBarCustom from "components/SideBarCustom";
import DynBanner from "components/DynBanner";
import DualContainer from "components/DualContainer";
import ImgDualContainer from "components/ImgDualContainer";
import ImgContainer from "components/ImgContainer";
import DynHandBanner from "components/DynHandBanner";
import DualTwoContainer from "components/DualTwoContainer";
import DualOptionContainer from "components/DualOptionContainer";
import StatisticsContainer from "components/StaticsContainer";
import OptionContainer from "components/OptionContainer";
import { bg1,bg2,bg3,bg4 } from "assets/img/images";
import { bg17 } from "assets/img/images";
import { bg16 } from "assets/img/images";
import { bg5 } from "assets/img/images";
import { bg18 } from "assets/img/images";

export default function Landing() {
  const navigate = useNavigate()
  return (
    <>
      
      <div className="fixed z-50 bottom-0 right-0 p-5">
        <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-600">
          <i className="fab fa-whatsapp text-3xl"></i>
        </div>
      </div>
      <Navbar/>
      <main className="bg-gradient-to-tr bg-white">
          <section>
            <DynHandBanner
              images = {[bg1,bg2,bg3,bg4]}
              texts = {[
                { title: "MASUR", subtitle: "Capital Investment" },
                { title: "Crecimiento de Inversiones", subtitle: "Tu futuro asegurado" },
                { title: "Expansión Empresarial", subtitle: "Empodera tu negocio" },
                { title: "Mercados Globales", subtitle: "Ve más allá de las fronteras" }  
              ]}
            />
          </section>
          <section className="">
            <DualTwoContainer
              imgMain={bg17}
              imgSecondary={bg16}
              title="CAPITAL INVESTMENT MASUR COMPANY"
              content={[
                "Nuestra Banca de Inversión Privada se especializa en la estructuración y gestión de vehículos de inversión que permiten optimizar el capital tanto propio como de amigos y familiares. Nuestro enfoque está en identificar y aprovechar oportunidades en sectores clave, tales como materias primas, eco-hoteles, y la construcción de obras.",
                "Dirigimos nuestros esfuerzos hacia la industria y la agroindustria, abarcando también áreas fundamentales como la alimentación y la minería. Nuestra experiencia y red de contactos nos permiten diseñar estrategias personalizadas que maximicen el rendimiento y minimicen los riesgos asociados a cada inversión." 
              ]}
              evt={() => navigate("/about")}
              />
              
              <ImgContainer
                bg={bg5}
                title=""
                colorb="border-emerald-700"
                reverse={true}
                content={[
                  <p
                    className="-mt-2 italic font-semibold"
                  >Nuestra filosofía se basa en la confianza y la transparencia, asegurando que nuestros clientes tengan acceso a inversiones bien estructuradas que ofrezcan seguridad y retornos sostenibles.</p>
                ]}
              >
              </ImgContainer>
              <StatisticsContainer
                  stats={[
                      { value: 4, description: "AÑOS DE EXPERIENCIA" },
                      { value: 15, description: "EQUIPO DE PROFESIONALES" },
                      { value: 7,pref:"K", description: "CLIENTES ATENDIDOS" }
                  ]}
              />

            <DualOptionContainer
              img={bg18}
              title="EN INVERSIÓN"
              options={[
                {
                  icon: "",
                  title: "Regla #1",
                  description: "Es nunca perder dinero."
                },
                {
                  icon: "",
                  title: "Regla #2",
                  description: "Es nunca olvidar la número uno."
                },
              ]}
              add={<span className="text-white italic text-2xl opacity-80 -my-2">Warren Buffett</span>}
              />
              <OptionContainer
              title="PERSONAL ESPECIALIZADO EN"
              options={[
                <ul className="list-disc text-left text-xl marker:text-emerald-700 space-y-4">
                  <li>Equipo Ejecutivo</li>
                  <li>Asistente Ejecutiva</li>
                  <li>Expertos</li>
                  <li>Gestión Financiera</li>
                  <li>Inversiones de Dpto.</li>
                  <li>Gestión de Riesgo</li>
                  <li>Gestión de Clientes</li>

                </ul>,
                <ul className="list-disc text-left text-xl marker:text-emerald-700 space-y-4">
                  <li>Finanzas Corporativas</li>
                  <li>Inversión Privada</li>
                  <li>Marketing y Comunicación</li>
                  <li>Legal</li>
                  <li>Cumplimiento</li>
                  <li>Recursos Humanos</li>
                  <li>Logística</li>
                </ul>
              ]}
              />
              
          </section>
      </main>
      <Footer />
    </>
  );
}
