import React from "react";
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import DynBanner from "components/DynBanner";
import DualContainer from "components/DualContainer";
import ImgDualContainer from "components/ImgDualContainer";
import ImgContainer from "components/ImgContainer";
import TitleBanner from "components/TitleBanner";
import ITable from "components/ITable";
import { bg9,bg11,bg12,bg13, main1,main2,main5,main4,main3,bg15 } from "assets/img/images";

export default function Services() {

  const investmentHeaders = ["ITEMS", "DETALLE", "SUCURSAL"];
  const investmentData = [
    {
      item: "101",
      detail: "AGRO, AGROINDUSTRIA, PECUARIA Y PESCADERÍA",
      branches: [
        "Agroindustrias del Pacífico S.A.S.",
        "Emprocrom S.A.S.",
        "Pacific Consulting Group S.A.S.",
        "Viñedo en Producción",
      ],
    },
    {
      item: "2001",
      detail: "INDUSTRIA: TRANSFORMACIÓN, EXPORTACIÓN E IMPORTACIÓN",
      branches: [
        "Colors & Flavors Industry Inc.",
        "Materias Primas de Suramérica",
        "Químicos de Colombia",
        "Pacific Consulting Group S.A.S.",
        "Eléctricos MASUR Ltda.",
        "Chocolates ISA",
      ],
    },
    {
      item: "3001",
      detail: "ALIMENTO, RESTAURANTE Y COSMETOLOGÍA",
      branches: [
        "Restaurante Puerto Pacífico",
        "Alimentos de las Américas S.A.S.",
        "Kraxvs S.A.S.",
        "Vinos Marca propia e importados",
        "Carnes importadas tipo: Angus Brangus, Wagyu y Kobe",
      ],
    },
    {
      item: "4001",
      detail: "MINERÍA: EXPLORACIÓN, EXPLOTACIÓN DE METALES NO PRECIOSOS",
      branches: ["Masur S.A.S.", "Invernegocios UPM S.A.S.", "Kraxvs S.A.S."],
    },
    {
      item: "5001",
      detail: "TEXTIL MARCA PROPIA Y MAQUILA",
      branches: [
        "Bontexcol (Ropa Íntima)",
        "Utría (Ropa Deportiva)",
        "Maquilas",
        "Kraxvs S.A.S.",
      ],
    },
    {
      item: "6001",
      detail: "ECO-HOTEL & CONSTRUCCIÓN DE OBRAS",
      branches: [
        "Alto tajo Eco-Hotel",
        "Masur",
        "Kraxvs S.A.S.",
        "Pacific Counsulting Group S.A.S.",
      ],
    },
  ];

  const descriptionHeaders = ["ITEM", "DESCRIPCIÓN", "CANTIDAD", "VALOR (U$DÓLAR)"];
const descriptionData = [
  {
    item: "101",
    description: "Capital investment MASUR Company",
    quantity: "Logística",
    value: "450.000 USD",
  },
  {
    item: "2001",
    description: "AGRO, AGROINDUSTRIA, PECUARIA Y PESCADERÍA",
    quantity: "Puesta en marcha de 3 Empresas",
    value: "16.000.000 USD",
  },
  {
    item: "301",
    description: "INDUSTRIA: TRANSFORMACIÓN, EXPORTACIÓN E IMPORTACIÓN",
    quantity: "Puesta en marcha de 6 Empresas",
    value: "50.000.000 USD",
  },
  {
    item: "401",
    description: "ALIMENTO, RESTAURANTE Y COSMETOLOGÍA",
    quantity: "Puesta en marcha de 5 Empresas",
    value: "9.000.000 USD",
  },
  {
    item: "501",
    description: "MINERÍA: EXPLORACIÓN, EXPLOTACIÓN DE METALES NO PRECIOSOS",
    quantity: "Puesta en marcha de 4 Minas",
    value: "24.000.000 USD",
  },
  {
    item: "601",
    description: "TEXTIL MARCA PROPIA Y MAQUILA",
    quantity: "Puesta en marcha de 1 Empresa",
    value: "3.000.000 USD",
  },
  {
    item: "701",
    description: "ECO-HOTEL & CONSTRUCCIÓN DE OBRAS",
    quantity: "Puesta en marcha de 2 Eco-Hoteles, 7 Bodegas y 3 Edificios de Apto.",
    value: "17.000.000 USD",
  },
];



  return (
    <>
      
      <div className="fixed z-50 bottom-0 right-0 p-5">
        <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-600">
          <i className="fab fa-whatsapp text-3xl"></i>
        </div>
      </div>
      <Navbar/>
      <main className="bg-gradient-to-tr bg-white">
          <section>
            <DynBanner
              images={[bg9,bg11,bg12,bg13]}
              title="Nuestros Servicios"
            ></DynBanner>
            <DualContainer
              img={main1}
              title="ASESORAMOS, FINANCIAMOS Y PRESTAMOS"
              content={[
                "Estructuramos vehículos de inversión seguros con garantías reales para inversión de capital propio, de amigos, familiares y clientes.",
                <span>Financiamos vía deuda compañías en Latinoamérica desde <b className="text-slate-600" >50.000 hasta 10 millones</b> de dólares.</span>,
                <span><b className="text-slate-600">Prestamos entre el 50 al 75% del valor de las garantías de las inversiones; </b>sean inversión ángeles o de capital de trabajo.</span>,
              ]}
              reverse={true}
              />
          </section>
          <section className="">
            <TitleBanner title="Productos y Servicios"/>
            <ImgContainer
              bg={main3}
              colort = "text-slate-800"
              colorb = "border-emerald-700"
              title="INVERSIÓN EN ACTIVOS"
              content={
                [
                  "Inversiones en bienes de desarrollo empresarial e industrial y que al desarrollarse generan mayor valor para los propietarios acelerando los procesos de valorización y monetización del activo.",
                ]
              }
            />
            <DualContainer
              img={main4}
              title={"CAPITAL DE TRABAJO"}
              content={[
                "Inversiones con rentabilidades y riesgo medio, donde una vez se cuenta con un producto validado comercialmente, fuentes de pago, garantías, el inversionista aporta los recursos para financiar la etapa operativa del proyecto.",
              ]}
              >
            </DualContainer>
             <ImgDualContainer
              bg={main2}
              img={main5}
              colort = "text-white"
              colorb = "border-emerald-200"
              height = "h-128"
              title={"INVERSIONES EN ACTIVOS PARA INVERSIÓN"}
              content={[
                "Son productos que cuentan con sistemas de operación y administración donde el inversionista recibe renta por la operación y explotación económica de esos activos, además de lograr una valorización en el tiempo y mayor valor generado por la capacidad del activo de generar flujo de caja.",
              ]}
              reverse={true}/>
            <DualContainer
              img={bg15}
              title="CAPITAL DE RIESGO"
              content={[
                "Inversión en desarrollo de nuevos proyectos en etapas tempranas que permiten obtener altas rentabilidades sobre la inversión."
              ]}
              />
           
          </section>
          <section>
            <TitleBanner title="Empresas"/>
            <ITable
              data={investmentData} headers={investmentHeaders}></ITable>
          </section>
          <section>
            <TitleBanner title="Propuestas Empresariales"/>
            <ITable
              data={descriptionData} headers={descriptionHeaders}></ITable>
          </section>
      </main>
      <Footer />
    </>
  );
}
