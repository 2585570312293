import React from "react";

export default function DualTwoContainer({ imgMain, imgSecondary, title, content, buttonText = "Conócenos", reverse = false, height="h-96", evt = null }) {
    return (
        <div className="container mx-auto px-2">
            <div className={`flex flex-wrap items-center justify-center my-32 ${reverse ? "flex-row-reverse" : ""}`}>
                {/* Image Section with fixed height */}
                <div className="w-full md:w-6/12 px-4">
                    <div className={"w-full mr-auto ml-auto relative my-10 " + height}>
                        <img
                            src={imgMain}
                            alt="Imagen principal"
                            className="absolute rounded-md shadow-xl object-cover h-5/6 w-3/4 top-0 left-0"
                        />
                        
                        <img
                                src={imgSecondary}
                                alt="Imagen secundaria"
                                className="absolute rounded-md shadow-xl object-cover bottom-0 right-0 h-3/6 w-2/6"
                            />
                    </div>
                </div>

                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                    <h3 className="text-slate-800 font-[Barlow] md:text-4xl lg:text-5xl text-xl mb-2 font-extrabold leading-normal">
                        {title}
                    </h3>
                    <hr className="w-24 py-2 mt border-emerald-700 border-t-8"></hr>
                    {content.map((v, i) => (
                        <p key={i} className="md:text-xl font-normal text-sm leading-relaxed mt-4 mb-4 text-slate-700 text-pretty">
                            {v}
                        </p>
                    ))}
                    <div className={buttonText.length === 0?"hidden ":""+ "w-full flex items-center justify-center"}>
                        <button className="mt-6 bg-green-700 hover:bg-green-600 text-white font-semibold text-lg py-4 px-6 rounded-lg shadow-lg" onClick={()=>{evt?.()}}>
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
