import React from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import DualContainer from "components/DualContainer";
import DualTwoContainer from "components/DualTwoContainer";
import DualOptionContainer from "components/DualOptionContainer";
import Banner from "components/Banner";
import { bg10,bg8,bg5,bg7,bg6 } from "assets/img/images";

export default function About() {
  const navigate = useNavigate()
  return (
    <>
      
      <div className="fixed z-50 bottom-0 right-0 p-5">
        <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-600">
          <i className="fab fa-whatsapp text-3xl"></i>
        </div>
      </div>
      <Navbar/>
      <main className="bg-gradient-to-tr bg-white">
          <section>
            <Banner
              bg={bg5}
            />
          </section>
          <section>
            <DualContainer
              img={bg7}
              title="¿QUIÉNES SOMOS?"
              content={[
                "Capital Investment MASUR Company es una firma de banca de inversión enfocada en diseñar estrategias financieras y oportunidades de inversión seguras y rentables. ",
                "Nos distinguimos por ofrecer soluciones exclusivas y a medida, gestionando fondos de manera eficiente y eficaz para capital propio y de nuestros socios cercanos. A través de un riguroso análisis de mercado y una planificación estratégica, brindamos oportunidades de crecimiento en mercados con alto potencial de expansión.",
              ]}
              reverse ={true}
              />
              <DualOptionContainer
              img={bg6}
              title="VALORES CORPORATIVOS"
              options={[
                {
                  icon: <i className="fa fa-handshake fill-emerald-700"></i>,
                  title:"Misión",
                  description:"Brindar alternativas de inversión de manera sencilla, simple y transparente para que nuestros inversionistas puedan tomar decisiones asertivas."
                },
                {
                  icon: <i className="fa fa-paper-plane fill-emerald-700"></i>,
                  title:"Visión",
                  description:"En 2030 ser reconocidos como uno de los mayores referentes de inversión privada en Latinoamérica. \nCreamos riqueza de forma responsable."
                }
              ]}
            />

            <DualTwoContainer
              imgMain={bg10}
              imgSecondary={bg8}
              reverse={true}
              height="h-128"
              buttonText = ""
              title="Fundada en 2020, con el objetivo de fomentar la inversión en Latinoamérica con respeto al medio ambiente."
              content={[
                <span className="py-10 text-2xl">Núcleo de inversión en sectores como:</span>,
                <span className="font-semibold"><div className="fa fa-tree mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Materias primas</span>,
                <span className="font-semibold"><div className="fa fa-building mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Eco hoteles</span>,
                <span className="font-semibold"><div className="fa fa-gavel mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Construcción</span>,
                <span className="font-semibold"><div className="fa fa-industry mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Industria</span>,
                <span className="font-semibold"><div className="fa fa-cogs mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Alimentos</span>,
                <span className="font-semibold"><div className="fa fa-leaf mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Agroindustria</span>,
                <span className="font-semibold"><div className="fa fa-hammer mx-3 flex align-middle items-center shadow-lg bg-emerald-700 text-white p-4 rounded-full"></div>Minería</span>,
              ]}
              />
          </section>
          
      </main>
      <Footer />
    </>
  );
}
