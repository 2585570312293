import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "views/Landing.js";
import Services from "views/Services";
import About from "views/About";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <BrowserRouter>
    <Routes>
      <Route path='/*' element={<Navigate to="home"/>} />
      <Route path='/home' element={<Landing/>} />
      <Route path='/service' element={<Services/>} />
      <Route path='/about' element={<About/>} />
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

